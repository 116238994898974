.faculty {
    margin-top: 50px;
    text-align: center;
}

.faculty .stemh_content {
    margin-top: -50px;
}

.faculty .regular_text {
    text-align: center;
}