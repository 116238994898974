.education {
    margin-top: 50px;
    text-align: center !important;
}

.education .education_title {
    padding: 45px 70px;
}

.education .highlight {
    padding: 20px 0px;
}

.education .highlight_content {
    text-align: center !important;
    font-size: larger;
    padding-top: 8%;
}

.education .regular_text {
    text-align: center;
    margin-top: -50px;
    margin-bottom: 100px;
}