.gallery {
    margin: 20px 50px;
}

.gallery .card {
    position: relative;
    border-radius: .5rem;
    border: 0px;
    margin-bottom: 30px;
    background-color: var(--main-contrast);
}

.gallery .card img {
    border: 2px solid var(--main-text);
    padding: 10px;
    margin: 20px;
}

.gallery .card-body {
    border-radius: 0 0 .5rem .5rem;
    border: 0px;
    border-top: 2px solid var(--main-bg-color) !important;
    text-align: center;
    margin-top: -2px;
}

.gallery .card-body .gallery_title {
    color: var(--main-bg-color);
    font-size: smaller;
    font-style: italic;
    font-weight: bold;
}

.gallery a {
    text-decoration: none;
}

.gallery button {
    color: var(--main-bg-color);
    border-color: var(--main-bg-color);
}

.gallery .card-body .gallery_name {
    display: inline;
}

@media only screen and (max-width: 1400px) {
    .gallery .card-body {
        font-size: larger;
    }
    .gallery .card-body .gallery_name {
        display: block;
        height: 50px;
        margin-bottom: -15px;
    }
    .gallery button {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 576px) {
    .gallery .card-body {
        font-size: larger;
    }
    .gallery .card-body .gallery_name {
        display: inline;
    }
}

.gallery button:hover {
    font-weight: bolder;
    border-color: var(--main-text);
    border-width: 2px;
}

.gallery .card-body:hover {
    color: var(--main-text);
    background-color: var(--main-bg-color);
    transition: color .4s ease-in-out, background-color .4s ease-in-out;
}

.gallery .card-body:hover span {
    color: var(--main-text);
}

.gallery .card-body:hover button {
    color: var(--main-text);
    border-color: var(--main-text);
}