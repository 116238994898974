.background {
    margin: 150px 50px;
}

.background .card {
    position: relative;
    border-radius: .5rem;
    border: 0px;
    margin-bottom: 30px;
    background-color: var(--main-contrast);
}

.background .card img {
    border: 2px solid var(--main-text);
    background-color: var(--main-bg-color);
}

.background .card-body {
    border-radius: 0 0 .5rem .5rem;
    border: 0px;
    border-top: 2px solid var(--main-text) !important;
    text-align: center;
    margin-top: -2px;
}

.background_text {
    text-align: justify;
}

.background button {
    color: var(--main-text);
    border-color: var(--main-text);
    margin-top: 30px;
    margin-left: 80%;
}

@media only screen and (max-width: 990px) {
    .background button {
        margin-left: 70%;
    }
}

.background button:hover {
    font-weight: bolder;
    border-color: var(--main-text);
    border-width: 2px;
}


/*
.background .card-body:hover {
    color: #fff;
    background-color: #1d1a1a;
    transition: color .4s ease-in-out, background-color .4s ease-in-out;
}
*/