.stemh_content .row {
    padding: 15px;
}

.stemh_content .portrait img {
    margin: 10px;
    width: 100%;
    padding: 5px;
    border: 2px solid var(--main-text);
}

.stemh_content .portrait p {
    margin-left: 20px;
}

.stemh_content .stock img {
    width: 100%;
    margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
    .stemh_content .stock img {
        width: 100%;
        margin: auto !important;
    }
    .stemh_content .stock {
        margin-bottom: 30px;
    }
}

.stemh_content .content_text {
    font-size: larger;
}

.stemh_content .content_list {
    margin-top: 20px;
}

.stemh_content .content_list ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.stemh_content .content_list ol {
    margin: 0 5px;
    padding: 0;
}

.stemh_content .content_list_item {
    display: inline;
}

.stemh_content .content_list ul li::before {
    font-family: 'FontAwesome';
    content: '\f192';
    padding-right: 10px;
}

.stemh_content .content_list_desc {
    margin-top: 20px;
    font-size: small;
}

.stemh_content .content_link {
    margin-top: -40px;
    margin-left: 40%;
}

@media only screen and (max-width: 990px) {
    .stemh_content .content_text {
        font-size: inherit;
    }
}

@media only screen and (min-width: 990px) {
    .stemh_content .content_link {
        margin-left: 50%;
    }
}

.stemh_content .content_link a {
    text-decoration: none;
}

.stemh_content .content_link button {
    color: var(--main-text);
    border-color: var(--main-text);
}

.stemh_content .content_link button:hover {
    font-weight: bolder;
    border-color: var(--main-text);
    border-width: 2px;
}


/* Style for the black background regular display */

.regular_text {
    margin-top: 50px;
    text-align: justify;
}

.regular_text .content_text h2 {
    text-align: center;
}

.regular_text .content_text h3 {
    margin-top: -30px;
}

.regular_text .content_text {
    margin: 50px 10%;
}

.regular_text .content_list ul li::before {
    color: var(--main-contrast);
}

.regular_text .content_list ol li::marker {
    color: var(--main-contrast);
}

.regular_text .stock img {
    box-shadow: 10px 10px 5px var(--alt-shadow);
}


/* Style for the orange background highlight display */

.highlight {
    background-color: var(--main-contrast);
    width: 100%;
    margin-bottom: 50px;
}

.highlight .content_text {
    padding: 6px;
    text-align: justify;
}

.highlight .content_list ul li::before {
    color: var(--main-text);
}

.highlight .content_list ol li::marker {
    color: var(--main-text);
}

.highlight .content_text h2 {
    color: var(--main-text);
    font-weight: bolder;
    margin-bottom: 20px;
}

.highlight .content_text h3 {
    color: var(--main-text);
    font-weight: bolder;
    margin-bottom: 20px;
}

.highlight .stock img {
    box-shadow: 10px 10px 5px var(--main-shadow);
}