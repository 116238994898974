.section_head {
    text-align: center;
    margin-bottom: 30px;
    padding-bottom: 4px;
    position: relative;
}

.section_head h1 {
    color: var(--main-text) !important;
}

.section_head span {
    padding-bottom: 4px;
    position: relative;
}

.section_head span::after {
    background: linear-gradient(to right, transparent 0%, var(--main-contrast) 50%, transparent 100%);
    content: "";
    position: absolute;
    bottom: 0;
    left: -50%;
    height: 2px;
    width: 200%;
}