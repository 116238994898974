/* Quattrocento */


/*@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');*/


/* Arsenal */

@import url('https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* */


/* Varta */


/*@import url('https://fonts.googleapis.com/css2?family=Varta:wght@300;400;500;600;700&display=swap');*/


/* Manrope */


/*@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');*/


/* EB Garamond */


/*@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');*/

body {
    /* font-family: 'Literata', serif; */
    /*font-family: 'Quattrocento Sans', sans-serif; */
    font-family: 'Arsenal', sans-serif;
    /* */
    /*font-family: 'Varta', sans-serif;*/
    /*font-family: 'Manrope', sans-serif;*/
    /*font-family: 'EB Garamond', serif;*/
    background-color: var(--main-bg-color) !important;
    font-size: 18px;
    /*width: min-content;*/
    width: 100%;
    color: var(--main-text) !important;
    min-width: 300px;
    max-width: 1300px;
    margin: auto;
}

@media only screen and (min-width: 976px) {
    body {
        width: initial;
    }
}

button:hover {
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5 {
    color: var(--main-contrast);
}

hr {
    border: 0;
    height: 1px;
    background-color: var(--main-contrast);
    border-radius: 5px;
}

.stemhigh_app {
    width: 100%;
}