.students_life {
    margin: 50px 0px;
    text-align: center !important;
}

.students_life .highlight .content_text {
    margin: 5px 10%;
}

.students_life h2 {
    text-align: center !important;
}