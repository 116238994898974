.admission {
    margin-top: 50px;
    text-align: center !important;
}

@media only screen and (min-width: 990px) {
    .admission {
        font-size: larger;
    }
}

.admission .admission_title {
    padding: 45px 70px;
}

.admission .content_list ul li span {
    color: var(--main-contrast);
    font-weight: bold;
}

.admission .stemh_content {
    margin-top: -60px;
}

.admission .admission_fees .content_list {
    padding-right: -20px;
    padding-left: 40px;
}

.admission .admission_fees img {
    margin-left: -20%;
    margin-top: 20%;
    width: 80%;
}

.admission .highlight {
    margin-top: 50px;
    margin-bottom: 50px;
}

.admission .highlight li {
    margin-left: 10%;
}

.admission .content_text span {
    color: var(--main-contrast);
    font-weight: bold;
}

.admission .content_text .deadline {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    .admission .admission_fees .content_list {
        padding-right: 0;
        padding-left: 0;
    }
    .admission .admission_fees .stock img {
        margin: 10% !important;
        width: 80%;
    }
}