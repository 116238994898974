.stem_footer {
    background-color: var(--main-bg-color);
    /* background-color: white;*/
    width: 100%;
    height: 120px;
    display: flex;
    border-top: 10px solid var(--main-contrast);
    margin-bottom: 25px;
    padding-top: 20px;
}

@media only screen and (max-width: 500px) {
    .stem_footer {
        display: flex;
        flex-direction: column;
    }
}

.left_foot {
    flex: 20%;
    text-align: center;
    margin-left: 10px;
}

.left_foot img {
    width: 60%;
    padding: 10px;
}

.mid_foot {
    flex: 60%;
    text-align: center;
    margin-right: 20px;
    font-size: small;
}

.right_foot {
    flex: 20%;
    text-align: center;
    margin-right: 10px;
}

.right_foot img {
    width: 60%;
    padding: 10px;
}