.academics {
    margin-top: 50px;
    text-align: center !important;
}

.academics .section_head {
    margin-bottom: 100px;
}

.academics .academic_paragraph {
    padding-top: 30px;
    margin-bottom: 50px;
}

.academics .academic_paragraph>img {
    margin: auto;
    width: 90%;
}

.academics .academic_paragraph .highlight {
    margin-top: -30px;
}

.academics h2 {
    text-align: center !important;
}

.academics .regular_text {
    margin-top: -10px !important;
}

.academics .regular_text .content_text {
    margin-top: -40px;
    padding-top: -10px;
}

.academics .highlight .content_text {
    margin: 5px 10%;
}

.academics .academic_paragraph .highlight img {
    margin-left: -45px;
}

@media only screen and (max-width: 768px) {
    .academics .academic_paragraph .highlight img {
        width: 80%;
    }
}