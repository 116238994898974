.stem_navbar {
    background-color: var(--main-bg-color);
    width: 100%;
    height: 120px;
    display: flex;
    border-top: 20px solid var(--main-contrast);
    position: relative;
    z-index: 5;
}

.stem_navbar button {
    color: var(--main-contrast);
    padding: 10px;
}

.stem_navbar .right_bar {
    flex: 70%;
    align-items: center;
    margin-right: 20px;
}

.stem_navbar .left_bar {
    flex: 30%;
    align-items: center;
    display: flex;
}

.stem_navbar .menu_button {
    display: none;
}

.stem_navbar .menu_button a {
    display: flex;
    text-decoration: none;
    font-weight: bold;
    color: var(--main-text) !important;
    margin: 15px;
}

.stem_navbar .menu_button button {
    color: var(--main-text);
}


/* Hide show the required elements of the menus */

@media only screen and (max-width: 750px) {
    .stem_navbar {
        height: 60px;
        border-top: 40px solid var(--main-contrast);
    }
    .stem_navbar .left_bar {
        display: none;
    }
    .stem_navbar .menu_button {
        display: flex;
    }
    .stem_navbar ul li a:hover {
        text-decoration: none;
    }
    .search_bar {
        display: none;
    }
    .stem_navbar ul {
        display: contents !important;
        position: absolute;
        left: 0px;
        top: 80px;
        height: auto;
        width: 100%;
        background-color: var(--main-bg-color);
    }
    .stem_navbar ul li {
        padding: 5px;
        margin-left: 10px;
    }
    .stem_navbar #hidden {
        visibility: hidden;
    }
}

.stem_navbar ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
}

.stem_navbar ul li a {
    text-decoration: none;
    background-color: transparent;
    color: var(--main-text);
    font-weight: bold;
}

.stem_navbar ul li a:hover {
    background-color: transparent;
    text-decoration: none;
    color: var(--main-contrast);
}

@media only screen and (max-width: 850px) {
    .stem_navbar ul li a {
        font-size: var(--second-font-size);
    }
}

@media only screen and (max-width: 750px) {
    .stem_navbar ul li a {
        font-size: var(--third-font-size);
    }
}

.active {
    color: var(--main-contrast) !important;
}

.cool-link {
    display: inline-block;
    color: var(--main-text);
    text-decoration: none;
}

.cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--main-contrast);
    transition: width .3s;
}

.cool-link:hover::after {
    width: 100%;
    transition: width .3 s;
}

.nav_logo {
    text-align: center;
    width: 70%;
    min-width: 150px;
    transition: all 0.5s ease 0s;
    background: var(--main-bg-color);
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.search_bar {
    padding: 5px;
    text-align: right;
    visibility: hidden;
}

.search_bar input {
    width: 200px;
    height: 30px;
    border: 2px solid var(--main-contrast);
    border-radius: 15px;
    font-size: 20px;
    padding-left: 5px;
    color: black;
}

.nav_links {
    margin-top: 5px;
    border-color: var(--main-contrast);
    border-style: solid none;
    border-width: 5px 0px;
    background-color: var(--main-bg-color);
    position: relative;
}