/* Modifying the style of the bootstrap component */

.message_carousel .carousel-indicators {
    visibility: inherit;
    bottom: -280px;
    position: relative;
}

.message_carousel .carousel-indicators button {
    width: 20px;
    height: 20px !important;
    border-radius: 100%;
    background-color: var(--main-bg-color);
    border: 2px solid var(--main-text);
}

.message_carousel .carousel-indicators .active {
    background-color: var(--main-contrast);
    border: 2px solid var(--main-bg-color);
}

.message_carousel .carousel-inner {
    overflow: visible;
    height: inherit;
}

.message_carousel .carousel-slide {
    height: inherit;
}

.message_carousel .carousel-item {
    height: inherit;
}


/* Unique to this component */

.message_carousel {
    padding: 25px 0px;
    background-color: var(--main-contrast);
    text-align: right;
    height: 280px;
    margin: auto;
    margin-bottom: 100px;
}

.message_carousel .carousel-caption {
    padding-top: 25%;
}

.message_carousel .carousel-item img {
    max-height: 250px;
    padding-right: 10%;
    max-width: 400px;
    object-fit: fill;
}

.message_carousel h3 {
    color: var(--main-text);
    margin: auto;
    font-size: 2.2rem;
}

.message_carousel h4 {
    color: var(--main-text);
    margin: auto;
    font-size: 1.7rem;
    margin-left: -30px;
    margin-left: -10px;
}

.message_carousel .left_caption {
    position: inherit;
    text-align: justify;
    font-size: var(--tertiary-image-font-size);
    width: 60%;
    margin-top: -18%;
}

.message_carousel .left_caption h3 {
    padding-bottom: 0px;
}

.message_carousel .left_caption p {
    margin: 0px 1px;
}

.message_carousel .left_caption ul {
    margin: 0px -35px;
}

@media only screen and (max-width: 1300px) {
    .message_carousel .left_caption {
        margin-top: -20%;
    }
}

@media only screen and (max-width: 1200px) {
    .message_carousel .left_caption {
        margin-top: -23%;
    }
}

@media only screen and (max-width: 1100px) {
    .message_carousel {
        height: 310px;
    }
    .message_carousel .left_caption {
        width: 60%;
        margin-top: -25%;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -300px;
    }
}

@media only screen and (max-width: 1000px) {
    .message_carousel {
        height: 360px;
    }
    .message_carousel .left_caption {
        width: 55%;
        margin-top: -29%;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -350px;
    }
}

@media only screen and (max-width: 900px) {
    .message_carousel {
        height: 410px;
    }
    .message_carousel .left_caption {
        width: 50%;
        margin-top: -29%;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -400px;
    }
}

@media only screen and (max-width: 850px) {
    /*shift */
    .message_carousel {
        width: 85%;
        height: 650px;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -640px;
    }
    .message_carousel .carousel-item {
        text-align: center;
    }
    .message_carousel img {
        max-width: 80%;
        min-width: 60%;
        padding-right: inherit !important;
    }
    .message_carousel .carousel-caption {
        position: relative;
        height: 120px;
    }
    .message_carousel .left_caption {
        margin-top: -15%;
        width: 80%;
    }
}

@media only screen and (max-width: 800px) {
    .message_carousel {
        width: 85%;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -640px;
    }
    .message_carousel .carousel-item {
        text-align: center;
    }
    .message_carousel img {
        max-width: 80%;
        min-width: 60%;
        padding-right: inherit !important;
    }
    .message_carousel .carousel-caption {
        position: relative;
    }
}

@media only screen and (max-width: 750px) {
    .message_carousel {
        height: 620px;
    }
    .message_carousel .carousel-caption {
        min-height: 170px;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -600px;
    }
}

@media only screen and (max-width: 650px) {
    .message_carousel {
        height: 620px;
    }
    .message_carousel .carousel-caption {
        min-height: 230px;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -600px;
    }
}

@media only screen and (max-width: 600px) {
    .message_carousel {
        height: 620px;
    }
    .message_carousel .carousel-caption {
        min-height: 255px;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -610px;
    }
}

@media only screen and (max-width: 550px) {
    .message_carousel {
        height: 610px;
    }
    .message_carousel .carousel-caption {
        min-height: 275px;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -600px;
    }
}

@media only screen and (max-width: 500px) {
    .message_carousel {
        height: 640px;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -630px;
    }
}

@media only screen and (max-width: 450px) {
    .message_carousel {
        height: 670px;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -660px;
    }
}

@media only screen and (max-width: 400px) {
    .message_carousel {
        height: 750px;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -740px;
    }
}

@media only screen and (max-width: 400px) {
    .message_carousel {
        height: 750px;
    }
    .message_carousel .carousel-indicators {
        visibility: inherit;
        bottom: -740px;
    }
}