.carousel-indicators {
    visibility: hidden;
}

.img-carousel {
    padding: 25px 0px;
    background-color: var(--main-bg-color);
    text-align: right;
    position: static;
}

.img-carousel img {
    max-height: 600px;
    min-height: 600px;
}

.img-carousel h2 {
    color: var(--main-text);
    line-height: 48px;
    text-shadow: 4px 4px var(--main-bg-color);
    margin-right: 20px;
    margin-left: 20px;
    font-size: 3.2rem;
    padding-bottom: 240px;
}

@media only screen and (max-width: 850px) {
    .img-carousel {
        padding-top: 45px;
    }
    .img-carousel h2 {
        font-size: 2.7rem;
    }
}

@media only screen and (max-width: 750px) {
    .img-carousel h2 {
        font-size: 2.2rem;
    }
}


/* Left component  */

.img-carousel .left_caption {
    text-align: center;
    width: 50%;
}

.img-carousel .left_caption h2 {
    padding-bottom: 0px;
}

.img-carousel .left_caption p {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 1rem;
    padding-bottom: 240px;
}

.img-carousel .left_caption span {
    color: var(--main-contrast);
}


/* Secondary image carousel styles. */

.img-carousel-edu {
    padding-bottom: 3px;
}

.img-carousel-edu img {
    max-height: 400px;
    min-height: 400px;
}

.img-carousel-edu h2 {
    font-size: 1.1em;
    margin: 0px;
    color: var(--main-bg-color);
    text-shadow: 2px 2px var(--main-text);
}