.facebook {
    background: var(--main-contrast);
}

.instagram {
    background: var(--main-contrast);
}

.youtube {
    background: var(--main-contrast);
}

.twitter {
    background: var(--main-contrast);
}

.whatsapp {
    background: var(--main-contrast);
}

.socialnets {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    position: fixed;
    z-index: 100;
}

.socialnets ul {
    padding: initial;
}

.socialnets ul li {
    height: 40px;
    width: 45px;
    list-style-type: none;
    padding-left: 8px;
    padding-top: 5px;
    padding-bottom: 10px;
    text-align: center;
    box-shadow: 3px 3px 1px var(--social-shadow);
}

.socialnets ul li a {
    color: var(--main-text);
    text-decoration: none;
}

.socialnets ul li:hover {
    padding-left: 18px;
    width: 55px;
    transition: ls;
}

.socialnets a:hover {
    color: var(--main-text);
}

@media only screen and (max-width: 750px) {
    .facebook {
        background: var(--facebook-color);
    }
    .instagram {
        background: var(--instagram-color);
    }
    .youtube {
        background: var(--youtube-color);
    }
    .whatsapp {
        background: var(--whatsapp-color);
    }
    .socialnets {
        display: initial;
        top: 15px;
        width: 100%;
    }
    .socialnets ul {
        width: 100%;
        list-style-type: none;
        display: flex;
        padding: 0;
        margin-bottom: 0;
    }
    .socialnets ul li {
        width: 100%;
        height: 30px;
        padding: 3px;
        box-sizing: border-box;
        color: var(--main-text);
        flex-wrap: nowrap;
        text-align: center;
    }
    .socialnets ul li:hover {
        transition: none;
        width: inherit;
        padding-left: 0px;
    }
    .socialnets li a {
        color: var(--main-text);
    }
}